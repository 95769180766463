
/*-Footer Menu-*/
#footer-bar {
    /* position: sticky; */
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 98;
    /* background-color: rgba(249, 249, 249, 0.98); */
    /* box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.06); */
    min-height: 60px;
    display: flex;
    text-align: center;
    transition: all 350ms ease;
  }
  
  #footer-bar a {
    color: #1f1f1f;
    padding-top: 12px;
    position: relative;
    flex: 1 1 auto;
  }
  
  #footer-bar a span {
    position: relative;
    z-index: 2;
    display: block;
    font-size: 10px;
    font-weight: 500;
    margin-top: -6px;
    opacity: 0.7;
    font-family: "Roboto", sans-serif !important;
  }
  
  #footer-bar a i {
    font-size: 18px;
    position: relative;
    z-index: 2;
  }
  
  #footer-bar .badge {
    font-style: normal;
    z-index: 5;
    top: 0px;
    position: absolute;
    margin-left: 3px;
    color: #FFF !important;
    width: 18px;
    text-align: center;
    line-height: 18px;
    padding: 0px;
    padding-left: 0px !important;
    border-radius: 18px;
    margin-top: 7px;
    font-size: 11px;
  }

/*-Pageapp Chat-*/
.speech-left {
    max-width: 200px;
    float: right;
    border-bottom-right-radius: 0px !important;
    color: #FFF;
  }
  
  .speech-right {
    float: left;
    max-width: 200px;
    border-bottom-left-radius: 0px !important;
  }
  
  .speach-image {
    padding: 0px !important;
  }
  
  .speach-image img {
    margin: 0px;
  }
  
  .speech-last {
    margin-bottom: 0px !important;
  }
  
  .speech-bubble {
    position: relative;
    border-radius: 17px;
    padding: 10px 15px;
    margin-bottom: 15px;
    line-height: 22px;
    font-size: 13px;
    background-color: #dee2e6;
    overflow: hidden;
  }
  
  .speech-bubble:after {
    content: '';
    display: block;
    clear: both;
  }
  
  .speech-read {
    font-size: 10px;
    font-style: normal;
    display: block;
    text-align: right;
    font-weight: 500;
    color: #adb5bd;
    margin-bottom: 80px;
  }
  
  .speach-input input {
    width: 100%;
    display: block;
    line-height: 45px;
    height: 45px;
    margin-top: 8px;
    border-radius: 45px;
  }
  
  .speach-icon a {
    padding-top: 0px !important;
    display: block;
    border-radius: 35px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    margin-top: 3px;
    transform: translateY(10px);
  }
  
  .speech-icon-left, .speech-icon-right {
    position: relative;
    display: block;
  }
  
  .speech-icon-left img,
  .speech-icon-right img {
    width: 30px;
    position: absolute;
    bottom: 0px;
  }
  
  .speech-icon-left img {
    left: 0px;
  }
  
  .speech-icon-right img {
    right: 0px;
  }
  
  .speech-icon-left .speech-bubble {
    margin-left: 20px !important;
  }
  
  .speech-icon-right .speech-bubble {
    margin-right: 20px !important;
  }
  div.chat {
    display: inline-block;
    width: 100%;
    background-color: #ececec;
    padding: 1em;
    overflow: auto;

  }

  .send-button {
    border-radius: 50%;
    padding: 11px;
    height: 3em;
    width: 3em;
    text-align: center;
    border-radius:50%
  }


  .modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
}
#fsChatModal .modal-content {
  border: none;
  border-radius: 0;
}
#fsChatModal .modal-body {
  padding: 0px;
}
#fsChatModal {
  padding-right: 0 !important;
}

.chat-button {
    display: inline-block;
    position: relative;
}

.chat-button i {
  color: green;
}

.chat-button .unread {
  font-size: 0.3em;
  background-color: red;
  height:1.5em;
  width:1.5em;
  border-radius: 50%;
  font-weight: bold;
  color: white;
  text-align: center;
  position: absolute;
  top:0.3em;
  right: 0px;
}
.chat-button i.has-unread {
  color: orange;
  
}