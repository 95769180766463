.add-button {
    color: #e76c56;
    font-size: 3em;
}

.buttons {
    width: 100%;
    text-align: right;
}
.header-bar {
    height: 80px;
}
.navbar {
    background-color: #fff1ea;
    box-shadow:  0 0 10px lightgray;
    /*border-color: #09bef5;*/
}

.navbar .navbar-nav .nav-link:not(:hover) {
    color: #6c757d !important
}
