.logo-header {
  height: 35px;
}.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.section-header {
  background-color: whitesmoke; /*#fff2ec; */
  height: 120px;
  padding: 10px 20px;
  position: relative;
  margin-bottom: 20px;
  box-shadow: 2px 2px 4px #AAA;
}

.section-header i {
  font-size: 5em;
  display: float;
  float: right;
}

.section-header .title {
  bottom: 10px;
  position: absolute;
  font-size: 2em;
  font-weight: bold;
  color: #e76c56;
} 
.modal-confirm {
  border-radius: 5px;
  box-shadow: #333 2px 2px 20px;
  background: white;
  overflow: hidden;
}
.modal-confirm .title{
  background: #fda600;
  color:white;
  padding: 5px 10px;
}
.modal-confirm .content {
  padding: 30px;
}
.modal-confirm .buttons {
  background: whitesmoke;
  padding:5px;
  text-align: center;
  border-top: 1px lightgray solid;        
}
.modal-confirm .buttons button.btn {
  margin: 0px 10px; 
}
.react-confirm-alert-overlay {
  z-index: 9999;
}
 
.main-panel > .content {
   padding: 0 10px 10px;
}
.wrapper-animation {
  height: 1em;
  width: 1em;
  line-height: 1em;
  text-align: center;
  display: inline-block;
}
div.wrapper-animation.slide {
  overflow: hidden;
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
@keyframes vslide {
  0% {
    top:-1em;
  }
  15% {
    top:-0.5em;
  }
  30% {
    top:0;
  }
  70% {
  	top:0em;
  }
  85% {
  	top:0.5em;
  }

  100% {
    top:1em;
  }
}

.vslide{
	animation: vslide 2s infinite;
  position: relative
}
@keyframes hslide {
  0% {
    left:-1em;
  }
  15% {
    left:-0.5em;
  }
  30% {
    left:0;
  }
  70% {
  	left:0em;
  }
  85% {
  	left:0.5em;
  }

  100% {
    left:1em;
  }
}
.hslide{
	animation: hslide 2s infinite;
  position: relative
}


.c-footer {
  margin-top: 5em;
  border-top: 2px whitesmoke groove;
  color: #333;
  text-align:center
}
.c-footer h6{
  color: #333;
}
.title {
  font-weight: 700;
}
h6, .h6 {
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
}
.c-footer ul li {
  display:list-item;
  color: #333;
}
.normal-link {
  text-transform: none !important;
  padding: 0 !important;
}
.c-footer .social i {
  font-size: 3em;
  color: dimgray;
  margin-right: 0.8em;
}
.c-footer .title {
  color:#e76c56;
  margin-top: 3em;
  line-height: 3em;
}
.c-footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}
.c-footer ul li a {
  padding-left: 0;
}

img.acception-logo  {
  width: 100px;
  margin-bottom: 20px;
}
.c-footer ul li a {
  padding-left: 0;
}
.c-footer ul li a {
  color: inherit;
  padding: 0.5rem;
  font-size: 0.8571em;
  text-transform: uppercase;
  text-decoration: none;
}

.colorpicker-in-input {
  float: right;
  margin-right: 10px;
  margin-top: -32px;
  position: relative;
  z-index: 2;
  color: green;
  font-size: 1.3em;
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) .container {
  padding:0px;
}
.wait-loading {
  height: 100%;
  width: 100%;
  display:flex;
  margin: auto;
}
.wait-loading img {
  height: 20vmin; 
  position: absolute; 
  top:calc(50% - 100px);
  left:50%;
  transform: translate(-50%, -50%);
}

.full-height-container {
  height: 100vh;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.both-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}